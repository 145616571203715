import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
    currentLevel: number = 1;
    currentquestion: number = 1;
    questionsPerLevel: number = 12;
    levelModifier: number = 1;

    numberOne: number = 0;
    numberTwo: number = 0;

    score: number = 0;

    totalAnswersCount: number = 4;
    answers = [];

    levelIntro: boolean = true;

    constructor() {
        this.generateRandomDigits();
        this.generateAnswers();
    }

    generateRandomDigits() {
        const minNumber = Math.floor(this.currentLevel / 4);
        const maxNumber = this.currentLevel + this.levelModifier;
        this.numberOne = Math.floor(Math.random() * (maxNumber - minNumber + 1)) + minNumber;
        // this.numberOne = Math.floor(Math.random() * (this.currentLevel + this.levelModifier));
        this.numberTwo = Math.floor(Math.random() * (maxNumber - minNumber + 1)) + minNumber;
        this.answers.push(this.numberOne + this.numberTwo);
    }

    generateAnswer(): number {
        let answer: number = Math.floor(Math.random() * (this.currentLevel + (this.levelModifier + 3)));

        for(let i = 0; i <= 8; i++) {
            if(this.answers.includes(answer)) {
                answer = Math.floor(Math.random() * (this.currentLevel + (this.levelModifier + 3)));
            }    
        }
        
        return answer;
    }

    generateAnswers() {
        for(let i = 0; i < this.totalAnswersCount - 1; i++) {
            let newAnswer: number = this.generateAnswer();
            this.answers.push(newAnswer);
        }
        this.shuffleAnswers();
    }

    shuffleAnswers() {
        var ctr = this.answers.length, temp, index;

        while (ctr > 0) {
            index = Math.floor(Math.random() * ctr);
            ctr--;
            temp = this.answers[ctr];
            this.answers[ctr] = this.answers[index];
            this.answers[index] = temp;
        }

        return this.answers;
    }

    giveAnswer(answer: number) {
        this.currentquestion++;

        if(this.numberOne + this.numberTwo === answer) {
            this.score++;
            this.playSound(true);
        } else {
            this.playSound(false);
            this.resetLevel();
        }

        // finish level
        if(this.currentquestion -1 === this.questionsPerLevel) {
            this.levelIntro = true;
        }

        this.answers = [];
        this.generateRandomDigits();
        this.generateAnswers();
    }

    playSound(correct: boolean) {
        const file = (correct) ? 'correct' : 'false';

        let audio2 = new Audio();
        audio2.src = '/assets/' + file + ".mp3";
        audio2.load();
        audio2.play();
    }

    resetLevel() {
        this.levelIntro = true;
        this.currentquestion = 1;
        this.score = 0;
    }

    startLevel() {
        this.levelIntro = false;
        this.score = 0;
        this.currentquestion = 1;
    }

    nextLevel() {
        this.levelModifier++;
        this.currentLevel++;
        this.startLevel();
    }
}
